















































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Logo75',
})
export default class Logo75 extends Vue {}
